import { Injectable, inject } from '@angular/core';
import { HttpRequest, HttpHandler,
  HttpEvent, HttpInterceptor,
  HttpErrorResponse,} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { TokenService } from '@services/token.service';
import { MockFlowService } from '@services/mock-flow.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  #mockFlowSvc = inject(MockFlowService);
  constructor(
    private tokenService: TokenService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('/auth/token-refresh')) {
      return next.handle(req);
    }

    let authReq = req;
    const token = this.#mockFlowSvc.getData('token_user');
    if (token != null && token != undefined) {
      authReq = this.addTokenHeader(req, token);
    }

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
      if (error && (error.status === 401 || error.status === 403)) {
        return this.handle401Error(authReq, next) as Observable<HttpEvent<any>>;
      }

      return throwError(error);
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

    return from(this.tokenService.getToken()).pipe(
      switchMap(res => {
        const token = res.data.token;
        this.#mockFlowSvc.setData({token_user: token})
          return next.handle(this.addTokenHeader(request, token));
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ setHeaders: {Authorization : `Bearer ${token}`}});
  }
}

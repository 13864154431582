import { ApplicationConfig, EnvironmentInjector, createComponent, importProvidersFrom, inject } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { routes } from './app.routes';
import { es_ES, provideNzI18n } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors, HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { GlobalTemplatesComponent } from './shared/components';
import { NZ_CONFIG, NzConfig } from 'ng-zorro-antd/core/config';

import { AutoTips } from './core/utilities';
import { NzModalService } from 'ng-zorro-antd/modal';

import { TokenInterceptor } from '@interceptors/token-interceptor';

// The Factory function
const nzConfigFactory = (): NzConfig => {
  const environmentInjector = inject(EnvironmentInjector);
  const { angleDownIcon, calendarIcon } = createComponent(GlobalTemplatesComponent, { environmentInjector }).instance;
  return {
    select: {
      nzSuffixIcon: angleDownIcon,
    },
    datePicker: {
      nzSuffixIcon: calendarIcon,
    },
    form: {
      nzNoColon: true,
      nzAutoTips: AutoTips,
    },
  };
};

registerLocaleData(es);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideNzI18n(es_ES),
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    importProvidersFrom(FormsModule, ReactiveFormsModule, HttpClientModule),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([])),
    { // The NG-ZORRO FactoryProvider
      provide: NZ_CONFIG,
      useFactory: nzConfigFactory
    },
    NzModalService
  ]
};

import { Routes } from '@angular/router';
import { environment } from '@environments/environment'

const fullRoutes: Routes = [
  {
    path: 'nuevo-credito',
    loadChildren: () => import('@pages/new-credit/new-credit.routing').then((r) => r.NewCreditRouting),
  },
  {
    path: 'seguimiento-credito',
    loadChildren: () => import('@pages/track-credit/track-credit.routing').then((r) => r.TrackCreditRouting),
  },
  {
    path: 'credito-rotativo',
    loadChildren: () => import('@pages/pdr-credit/pdr-credit.routing').then((r) => r.PdrCreditRouting),
  },
  {
    path: '404',
    loadComponent: () => import('@pages/not-found/not-found.component').then((c) => c.NotFoundComponent),
  },
  {
    path: '**',
    redirectTo: '/404',
    pathMatch: 'full',
  },
];

const singleRoute: Routes = [
  {
    path: 'nuevo-credito',
    loadChildren: () => import('@pages/new-credit/new-credit.routing').then((r) => r.NewCreditRouting),
  },
  {
    path: '404',
    loadComponent: () => import('@pages/not-found/not-found.component').then((c) => c.NotFoundComponent),
  },
  {
    path: '**',
    redirectTo: '/404',
    pathMatch: 'full',
  },
];

export const routes: Routes = environment.SINGLEROUTE ? singleRoute : fullRoutes;

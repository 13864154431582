import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ScrollingModule } from "@angular/cdk/scrolling";

import { NzLayoutModule } from 'ng-zorro-antd/layout';

import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    NzLayoutModule,
    ScrollingModule
  ],
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(
    library: FaIconLibrary
  ) {
    // Add multiple icons to the library
    library.addIconPacks(fas,far,fal);
  }

  ngOnInit() {
    // console.log(environment.ENTERPRISE)
    document.title = 'CrediEnLínea - ' + environment.ENTERPRISE;
  }
}
